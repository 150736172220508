<template>
    <div>
        <v-row v-if="supplier" no-gutters>
            <v-col cols="2" class="d-flex align-center">
                <span>Contacts</span>
            </v-col>
            <v-col class="d-flex align-center flex-wrap">
                <ContactSupplier
                    v-for="contact in filterContacts"
                    :key="contact.id"
                    :contact="contact"
                    @removeContact="removeContact"
                    @replaceContact="replaceContact"
                />
                <div>
                    <v-btn
                        depressed
                        fab
                        x-small
                        color="primary"
                        @click="openCreateDialogNew"
                    >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <v-dialog
                        :retain-focus="false"
                        v-model="createDialogNew"
                        persistent
                        max-width="600px"
                    >
                        <ContactSupplierForm
                            title="New Contact"
                            :create-form="true"
                            @closeDialog="closeCreateDialogNew"
                            @addContact="addContact"
                            :supplierId="supplier.id"
                        />
                    </v-dialog>
                </div>
            </v-col>
        </v-row>

        <v-row no-gutters v-if="supplier" class="pb-2 pt-2">
            <v-col cols="2" class="d-flex align-center">
                <span>City</span>
            </v-col>
            <v-col class="d-flex align-center">
                <span>{{ supplier.city }}</span>
            </v-col>
        </v-row>
        <v-row no-gutters v-if="supplier" class="pb-2">
            <v-col cols="2" class="d-flex align-center">
                <span>Currency</span>
            </v-col>
            <v-col class="d-flex align-center">
                <span
                    >{{ supplier.currency
                    }}{{
                        supplier.currency2 ? ` - ${supplier.currency2}` : ''
                    }}</span
                >
            </v-col>
        </v-row>
        <v-row v-if="supplier" no-gutters class="pb-2">
            <v-col cols="2" class="d-flex align-center">
                <span>Language</span>
            </v-col>
            <v-col class="d-flex align-center">
                <v-avatar size="24" class="me-2 my-1">
                    <v-img
                        :src="
                            supplier.language.toLowerCase() === 'english'
                                ? require('@/assets/usa_flag.png')
                                : require('@/assets/col_flag.png')
                        "
                    />
                </v-avatar>
            </v-col>
        </v-row>
        <v-row v-if="supplier" no-gutters class="pb-2">
            <v-col cols="2" class="d-flex align-center">
                <span>SAP Status</span>
            </v-col>
            <v-col class="d-flex align-center">
                <span>{{ supplier.sapStatus ? 'On SAP' : 'Not on SAP' }}</span>
            </v-col>
        </v-row>
        <v-divider class="my-4" />
    </div>
</template>

<script>
import ContactSupplierForm from '@/components/Suppliers/ContactSupplierForm'
import ContactSupplier from '@/components/Suppliers/ContactSupplier'
import codesCurrency from '@/assets/codes-all_json.json'
import { mapMutations } from 'vuex'
import API from '@/services/api'
import { storage } from '@/services/firebase'

export default {
    name: 'SupplierGeneralInfo',
    components: {
        ContactSupplierForm,
        ContactSupplier,
    },
    props: {
        id: String,
        supplier: Object,
    },
    data() {
        return {
            createDialogNew: false,
            itemsCurrency: null,
            loading: false,
            error: false,
            errorMsg: null,
            companyId: JSON.parse(localStorage.getItem('company')),
            folderPictures: 'suppliers_pictures',
            val: false,
        }
    },
    watch: {
        supplier: async function() {
            await this.getContactsBySupplier()
            this.val = false
            this.val = true
        },
    },
    computed: {
        filterContacts: function() {
            return this.val ? this.supplier.contacts : []
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        openCreateDialogNew: function() {
            this.createDialogNew = true
        },
        selectCurrency: function(event) {
            if (this.supplier.exchange) {
                this.supplier.exchange.currency = event
            } else {
                this.supplier.exchange = { currency: event }
            }
        },
        selectMoney: function(event) {
            if (this.supplier.exchange) {
                this.supplier.exchange.money = event
            } else {
                this.supplier.exchange = { money: event }
            }
        },
        closeCreateDialogNew: function() {
            this.createDialogNew = false
        },
        closeDialogExchange: function() {
            this.dialogExchange = false
        },
        addContact: function(contact) {
            this.supplier.contacts.push(contact)
        },
        removeContact: function(id) {
            const index = this.supplier.contacts.findIndex(u => u.id === id)
            this.supplier.contacts.splice(index, 1)
            this.val = false
            this.val = true
        },
        replaceContact: function(id, contact) {
            const index = this.supplier.contacts.findIndex(u => u.id === id)
            this.supplier.contacts.splice(index, 1, contact)
            this.val = false
            this.val = true
        },
        saveExchange: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                const { id } = this.supplier
                const supplier = await API.updateSupplier(id, {
                    exchange: this.supplier.exchange,
                })
                supplier.projects = this.supplier.projects
                supplier.contacts = this.supplier.contacts
                supplier.companyRoles = this.supplier.companyRoles
                supplier.profilePicURL = this.supplier.profilePicURL
                this.closeDialogExchange()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async getContactsBySupplier() {
            try {
                this.loading = true
                const contacts = await API.getContactsBySupplier({
                    supplierId: this.supplier.id,
                })
                for (const contact of contacts) {
                    if (contact.picture) {
                        const path = `${this.companyId}/${this.folderPictures}`
                        const storageRef = storage().ref(path)
                        const profilePicRef = storageRef.child(contact.picture)
                        await profilePicRef
                            .getDownloadURL()
                            .then(url => {
                                contact.profilePicURL = url
                            })
                            .catch(error => {
                                contact.profilePicURL = ''
                                return error && true
                            })
                    }
                }
                this.supplier.contacts = contacts || []
                this.val = true
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
    async created() {
        await this.getContactsBySupplier()
        this.itemsCurrency = codesCurrency.map(x => x.AlphabeticCode)
    },
}
</script>

<style scoped>
.text-singleline {
    line-height: 0.75rem;
}
</style>
